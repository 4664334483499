* {
  font-family: "Monda", Sans-Serif;
}

body {
  color: white;
  background-color: #03192d;
}

a:visited {
  text-decoration: none;
  color: #ffd166;
  margin-bottom: 1rem;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: #ffd166;
}

.title-component {
  margin-bottom: 7vh;
  color: #ef476f;
  text-shadow: 1.5px 1.5px #701f32;
}

p {
  text-align: justify;
}

.App {
  text-align: center;
}

.components {
  padding: 10vh 4vw;
}

.social-medias {
  display: flex;
  justify-content: space-around;
}

i {
  margin: 2rem 2rem;
}

.section-container {
  padding-top: 1rem;
  padding-bottom: 4rem;
}

#skills, #about-me-section {
  background: #142241;
}

/* Header */

.App-header {
  min-height: 100vh;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-bottom: 15vh;
  width: 99vw;
}

/*ANIMATION*/
#bg-animation-box {
  background-image: url("./images/blur-image.png");
  background-size: cover;
  width: 99vw;
}
#home_animation {
  font-size: 9vw;
  color: white;
  font-weight: bold;
  font-family: sans-serif;
  margin: 9vh auto;
}

#span-animation {
  border-right: 0.05em solid;
  animation: caret 1s steps(1) infinite;
  color: white;
}

/* my name on top */

.my_name_text,
.web_dev {
  text-align: center;
}

.my_name_text {
  font-size: 10vh;
  color: #00ffcd;
  font-family: "Monda", Sans-Serif;
  font-weight: 800;
}

.web_dev {
  font-weight: 100;
  letter-spacing: 9px;
  color: #ef476f;
  margin: -20px auto 10px auto;
}

/* submenu - home */

.submenu-container {
  font-weight: lighter;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  border-top: white solid 1px;
  margin: 0 auto;
}

.submenu-name {
  justify-content: center;
  padding: 10px;
  margin-top: 1rem;
}

/* Projects */

.cards {
  display: grid;
  justify-content: center;
  grid-gap: 7vw;
  grid-template-columns: repeat(auto-fit, minmax(200px, 330px));
  /* margin-top: 10vh; */
}

.card {
  display: flex;
  background: #ef476f;
  color: white;
  border: none;
  justify-content: center;
  transform: translate(25px, 25px);
  min-height: 500px;
}

.font-text-card {
  font-size: 12px;
}

/*skills component*/

#skills {
  background: #142241;
}

.container {
  justify-content: center;
  text-align: center;
}

.row {
  justify-content: center;
}

.col-logos {
  min-width: 4rem;
  display: grid;
  grid-gap: 5px;
  padding: 5px;
  justify-content: center;
}

.icon-logo {
  max-width: 6rem;
}

.text-logo {
  max-width: 10rem;
}

.skills-text{
  margin: 1rem 0;
}

a:link {
  text-decoration: none;
}

img {
  width: 70%;
  height: auto;
}

/* contact me */

.contact-me-container {
  justify-content: center;
}

.form-group {
  display: grid;
  margin-top: 1vh;
  color: #e3e6e8;
  text-align: left;
}

.follow-me-box {
  display: grid;
  align-items: end;
  height: 34vh;
}

.fa-4x {
  margin: 1rem;
}

/*footer*/

footer {
  padding: 3vh 0px;
  background-color: #03192d;
}

/* about me */

.aboutme {
  margin: auto 7vw;
  font-weight: 300;
  font-size: calc(6px + 2vmin);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 4.5vh auto;
}

.googling {
  color: #00ffcd;
  font-size: 47px;
}

.text-aboutme {
  padding: 2rem;
}

.img-aboutme {
  margin: 2rem;
}

.card-img-top {
  max-width: 600px;
}

.pic-card {
  display: flex;
  color: white;
  border: none;
  justify-content: center;
  transform: translate(25px, 25px);
}

@media only screen and (max-width: 600px) {
  .my_name_text {
    font-size: 10vw;
    color: #00ffcd;
    font-family: "Julius Sans One", Sans-Serif;
  }

  .web_dev {
    font-size: 5vw;
    letter-spacing: 0px;
    color: #ef476f;
    margin: -5px 0 0 0;
  }

  .submenu-container {
    display: unset;
  }

  .aboutme {
    display: grid;
  }

  .googling {
    font-size: calc(8px + 2vmin);
  }

  .img-aboutme {
    margin: 0rem 8rem 0 6rem;
}

  .cards {
    grid-gap: 15vw;
    grid-template-columns: repeat(auto-fit, minmax(200px, 270px));
    /* margin-top: 10vh; */
  }

  .card {
    transform: translate(10px, 10px);
  }
  .card-text {
    font-size: 10px;
  }

  .col-logos {
    min-width: 70px;
    max-width: 120px;
    display: grid;
    grid-gap: 5px;
    padding: 5px;
    justify-content: center;
  }

  .contact-me-container {
    grid-template-columns: 1fr;
    grid-row-gap: 80px;
  }
}
